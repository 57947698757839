import React, { useState } from 'react'
import { InputNumber } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase23(props) {
  const [inputValue2, setInputValue2] = useState(props?.data?.roi2)
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.roi2 = inputValue2
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }

  return (
    <>
      <QuestionsHeader titleHead="Meeting Minutes for Integrated Concurrent Engineering (ICE)" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #3</div>
        <div className="survey-slider__question headingLegendBold">
          How many meeting resolutions do you have to track per year?
        </div>
        <div className="survey-slider__inputNumber">
          <InputNumber
            min={0}
            max={100}
            style={{ margin: '0 16px' }}
            value={inputValue2}
            onChange={onChange2}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Back" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase23
